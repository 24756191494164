import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";

import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { ImagesService } from "../../services/helper/images.service";
import { PostsService } from "../../services/posts/posts.service";
import { ProjectService } from "../../services/project/project.service";
import { DialogCarouselComponent } from "./dialog-carousel/dialog-carousel.component";

@Component({
	selector: "image-documents-carousel",
	templateUrl: "./image-documents-carousel.component.html",
	styleUrls: ["./image-documents-carousel.component.scss"],
})
export class ImagenDocumentCarouselComponent implements OnInit {
	@Input("objects") objects: any;
	objetos;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private sanitizer: DomSanitizer,
		private snackBar: MatSnackBar,
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private postsSvc: PostsService,
		public dialog: MatDialog,
	) {}

	ngOnInit(): void {
		this.objetos = this.objects;
	}

	showDialog(index) {
		const dialogRef = this.dialog.open(DialogCarouselComponent, {
			width: "80svw",
			height: "80vh",
			autoFocus: false,
			data: { objetos: this.objetos, index },
		});

		dialogRef.afterClosed().subscribe((data) => {});
	}
}
