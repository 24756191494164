import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PostsService } from "app/services/posts/posts.service";
import mime from "mime";
import { map } from "rxjs";

@Component({
	selector: "app-dialog-carousel",
	templateUrl: "./dialog-carousel.component.html",
	styleUrls: ["./dialog-carousel.component.scss"],
})
export class DialogCarouselComponent implements OnInit {
	max: number;
	min: number = 0;
	index: number;
	objetos = [];
	selected;
	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private postSvc: PostsService,
	) {
		this.index = this.dialogData.index;
		this.objetos = this.dialogData.objetos;
	}
	ngOnInit() {
		this.selected = this.objetos[this.index];
		this.max = this.objetos.length ? this.objetos.length - 1 : 0;
	}
	changeIt(number) {
		//number puede se +1 o -1
		if (number == -1) {
			this.index = this.index != 0 ? this.index - 1 : 0;
		}
		if (number == 1) {
			this.index = this.index < this.max ? this.index + 1 : this.max;
		}
		this.selected = this.objetos[this.index];
	}

	previewDocument(document) {
		const mimeType = mime.getType(document.fileName) || "application/octet-stream";
		this.postSvc
			.getDocument(document.id)
			.pipe(map((response: any) => new Blob([response], { type: mimeType })))
			.subscribe((blob: Blob) => {
				const fileURL = URL.createObjectURL(blob);
				window.open(fileURL, "_blank");
			});
	}
}
