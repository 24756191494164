<div fxLayout="row wrap">
	<div fxLayout="column" fxLayoutAlign="center center" *ngFor="let elem of objects; let i = index" style="cursor: pointer" (click)="showDialog(i)">
		<div
			fxLayout="row"
			fxLayoutAlign="center center"
			style="
				width: 100px;
				height: 100px;
				border: 2px solid var(--feedbalia-color-primary-500);
				background-color: var(--feedbalia-color-background-500);
				border-radius: 5px;
				margin: 2px;
				cursor: pointer;
			"
		>
			<ng-container *ngIf="elem.type === 'img'; else fileTemplate">
				<img class="imagenpw" [src]="elem.thumbImage" alt="-" />
			</ng-container>
			<ng-template #fileTemplate>
				<div fxLayout="column" fxLayoutAlign="center center" style="cursor: pointer">
					<div fxLayout="row" fxLayoutAlign="center center" style="cursor: pointer">
						<fa-icon style="cursor: pointer" icon="file"></fa-icon>
					</div>
					<div fxLayout="row" fxLayoutAlign="center center" style="cursor: pointer">
						<span class="mt-8" style="text-align: center; font-size: 10px; font-style: italic">
							{{ elem.title }}
						</span>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>
