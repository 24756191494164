<div class="panel-plan-accion mb-16" [ngClass]="{ backgroundNone: projectName == 'funespana' || projectName == 'enalta' }">
	@if (permisos.w) {
		<div
			fxLayout="row"
			fxLayoutAlign="space-between center"
			class="panel-header"
			*ngIf="datos.disable_request === 1 && !editMode && person.id === user.id"
		>
			<div class="botonera-left" fxLayoutAlign="center center">
				<div fxLayoutAlign="center center">
					<span class="ml-4">* Deshabilitación solicitada al manager</span>
				</div>
			</div>
			<div *ngIf="!editMode" class="botonera-right" fxLayoutAlign="center center">
				<div fxLayoutAlign="center center" class="btn-footer end boton-red" matTooltip="Cancelar deshabilitación" (click)="revocarCancelacion()">
					<mat-icon>clear</mat-icon>
				</div>
			</div>
		</div>
	}
	@if (permisos.w) {
		<div
			fxLayout="row"
			fxLayoutAlign="space-between center"
			class="panel-header"
			*ngIf="datos.disable_request === 1 && !editMode && person.id !== user.id"
		>
			<div class="botonera-left" fxLayoutAlign="center center">
				<div fxLayoutAlign="center center">
					<span class="ml-4">Solicitada deshabilitación por parte del usuario</span>
				</div>
			</div>
			<div *ngIf="!editMode" class="botonera-right" fxLayoutAlign="center center">
				<div
					fxLayoutAlign="center center"
					class="btn-footer end boton-green"
					matTooltip="Aceptar deshabilitación"
					(click)="aceptarCancelacionManager()"
				>
					<mat-icon>check</mat-icon>
				</div>
				<div
					fxLayoutAlign="center center"
					class="btn-footer end boton-red"
					matTooltip="Cancelar deshabilitación"
					(click)="revocarCancelacionManager()"
				>
					<mat-icon>clear</mat-icon>
				</div>
			</div>
		</div>
	}

	<div fxLayout="row" fxLayoutAlign="space-between center" class="panel-header" *ngIf="datos.noApply === 1 && user.id === person.id">
		<div class="botonera-left" fxLayoutAlign="center center">
			<div fxLayoutAlign="center center">
				<span class="ml-4">Plan de acción deshabilitado</span>
			</div>
		</div>
	</div>

	<div fxLayout="row" fxLayoutAlign="space-between center" class="panel-header" *ngIf="datos.noApply === 1 && user.id !== person.id">
		<div class="botonera-left" fxLayoutAlign="center center">
			<div fxLayoutAlign="center center">
				<span class="ml-4">Plan de acción deshabilitado</span>
			</div>
		</div>
		@if (permisos.w) {
			<div *ngIf="!editMode" class="botonera-right" fxLayoutAlign="center center">
				<div fxLayoutAlign="center center" class="btn-footer end boton-violet" matTooltip="Reactivar" (click)="reactivarPdaManager()">
					<mat-icon>settings_backup_restore</mat-icon>
				</div>
			</div>
		}
	</div>

	<div fxLayout="row" class="panel-body p-16" [class]="classEstadoPlanAccion(userResult)" [ngClass]="{ 'pda-disabled': datos.noApply === 1 }">
		<div *ngIf="person" class="person-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
			<img class="img-circle" [src]="getImagePerson(person.username)" [alt]="person.displayName" />
		</div>

		<form fxLayout="column" class="px-16 w-100-p gap-1" [formGroup]="formActionPlans">
			<div fxLayout="row" fxLayoutAlign="space-between flex-start">
				<!-- Title -->
				<div>
					<span *ngIf="datos.required && !readOnly" class="alert-warning required-container" fxLayoutAlign="flex-start center">
						Elemento requerido
						<!-- <mat-icon>warning</mat-icon> -->
					</span>

					<h4 *ngIf="!editMode" class="m-0 font-size-18" [innerHTML]="datos?.title"></h4>
					<mat-form-field subscriptSizing="dynamic" *ngIf="editMode" class="w-100-p" appearance="outline">
						<mat-label>Introduce un título</mat-label>
						<input matInput class="m-0 font-size-18" formControlName="title" />
					</mat-form-field>
				</div>

				<div fxLayout="column">
					<!-- ReadOnly -->
					<div *ngIf="readOnly && userResult" fxLayout="row" fxLayoutAlign="flex-end center" class="mb-8">
						<button
							mat-mini-fab
							class="ml-4"
							[class]="classEvaluacion(userResult)"
							[matTooltip]="titleEvaluacion(userResult)"
							matTooltipPosition="above"
						>
							<mat-icon>{{ iconEvaluacion(userResult) }}</mat-icon>
						</button>
					</div>
					<!-- WriteMode -->
					@if (permisos.w) {
						<div *ngIf="!readOnly && !editMode" fxLayout="row" class="botonera-planes-accion mb-8">
							<button
								mat-mini-fab
								class="ml-4 btn-success"
								[class]="userResult !== '100' ? 'no-select' : ''"
								matTooltip="Completado"
								matTooltipPosition="above"
								(click)="setResult('100')"
							>
								<mat-icon>check</mat-icon>
							</button>
							<button
								mat-mini-fab
								class="ml-4 btn-warning"
								[class]="userResult !== '50' ? 'no-select' : ''"
								matTooltip="En progreso"
								matTooltipPosition="above"
								(click)="setResult('50')"
							>
								<mat-icon>access_time</mat-icon>
							</button>
							<button
								mat-mini-fab
								class="ml-4 btn-danger"
								[class]="userResult !== '0' ? 'no-select' : ''"
								matTooltip="No completado"
								matTooltipPosition="above"
								(click)="setResult('0')"
							>
								<mat-icon>close</mat-icon>
							</button>
							<button
								mat-mini-fab
								class="ml-4 btn-muted"
								[class]="userResult !== 'NA' ? 'no-select' : ''"
								matTooltip="No aplica"
								matTooltipPosition="above"
								(click)="setResult('NA')"
								*ngIf="projectName != 'syrsa'"
							>
								<mat-icon>pause</mat-icon>
							</button>
						</div>
					}
					<div fxLayout="column" fxLayoutAlign="flex-end flex-end">
						<div *ngFor="let itemEval of datos.evaluators" fxLayout="row" class="label-evaluation">
							<div class="person-avatar" fxLayout="row" fxLayoutAlign="flex-end center">
								<img class="img-circle" [src]="getImagePerson(itemEval.nickname)" [title]="itemEval.displayName" />
							</div>
							<div class="btn-round-xs" [class]="classEvaluacion(itemEval.result)" [title]="titleEvaluacion(itemEval.result)">
								<mat-icon class="font-size-12">{{ iconEvaluacion(itemEval.result) }}</mat-icon>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Description -->
			<div *ngIf="!editMode">
				<div [innerHTML]="datos?.description"></div>
			</div>
			<mat-form-field subscriptSizing="dynamic" *ngIf="editMode" class="w-100-p" appearance="outline">
				<mat-label>Introduce una descripción</mat-label>
				<textarea matInput class="m-0 font-size-18" formControlName="description"></textarea>
			</mat-form-field>

			<!-- Dates -->
			<div *ngIf="!editMode" fxLayout="row">
				<span class="mr-8">{{ "general.start_date" | translate }}: {{ formatDate(datos.startDate) }}</span>
				<span>{{ "general.end_date" | translate }}: {{ formatDate(datos.endDate) }}</span>
			</div>
			<div *ngIf="editMode" fxLayout="row" class="dates">
				<!-- Start Date -->
				<mat-form-field subscriptSizing="dynamic" appearance="fill" class="mr-12">
					<mat-label>{{ "general.start_date" | translate }}</mat-label>
					<input matInput [matDatepicker]="pickerStartDate" formControlName="startDate" />
					<mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
					<mat-datepicker #pickerStartDate></mat-datepicker>
				</mat-form-field>
				<!-- End Date -->
				<mat-form-field subscriptSizing="dynamic" appearance="fill">
					<mat-label>{{ "general.end_date" | translate }}</mat-label>
					<input matInput [matDatepicker]="pickerEndDate" formControlName="endDate" />
					<mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
					<mat-datepicker #pickerEndDate></mat-datepicker>
				</mat-form-field>
			</div>
		</form>
	</div>

	<div fxLayout="row" fxLayoutAlign="space-between center" class="panel-footer" *ngIf="datos.noApply === 0">
		<div class="botonera-left" fxLayoutAlign="center center">
			<div fxLayoutAlign="center center" class="btn-footer" matTooltip="Comentarios" (click)="toogleFormComment()">
				<!-- <span class="font-size-14 mr-4">{{datos.numComments}}</span> -->
				<span *ngIf="datos.comments !== '0'" class="mr-4">{{ datos.comments }}</span>
				<mat-icon>chat_bubble</mat-icon>
			</div>
			<div fxLayoutAlign="center center" class="btn-footer" matTooltip="Revisiones" (click)="showRevisions()" *ngIf="projectName != 'iberext'">
				<mat-icon>history</mat-icon>
			</div>
		</div>
		<div *ngIf="editable">
			<div *ngIf="!editMode" class="botonera-right" fxLayoutAlign="center center">
				@if (permisos.w) {
					<div fxLayoutAlign="center center" class="btn-footer end" matTooltip="Editar plan de acción" (click)="toogleEdit()">
						<mat-icon>edit</mat-icon>
					</div>
				}
				@if (permisos.w) {
					<ng-container *ngIf="projectName == 'leroymerlin'">
						<!-- Aplicamos el nuevo criterio de Leroy-->
						<div
							*ngIf="datos.disable_request == 0 && person.id === user.id"
							fxLayoutAlign="center center"
							class="btn-footer end"
							matTooltip="Eliminar plan de acción"
							(click)="solicitarCancelacion()"
						>
							<mat-icon>not_interested</mat-icon>
						</div>
					</ng-container>
				}
				<ng-container *ngIf="projectName != 'leroymerlin'">
					@if (permisos.d) {
						<div fxLayoutAlign="center center" class="btn-footer end" matTooltip="Eliminar plan de acción" (click)="removePA()">
							<mat-icon>delete</mat-icon>
						</div>
					}
				</ng-container>
				@if (permisos.w) {
					<div
						*ngIf="revisionPeriodEnabled && user.id !== person.id && canBeBlocked"
						fxLayoutAlign="center center"
						class="btn-footer end"
						matTooltip="Bloquear plan de acción"
						(click)="lockActionPlan(1)"
					>
						<mat-icon>lock</mat-icon>
					</div>
					<!-- Revision de PA -->
					<div *ngIf="revisionPeriodEnabled && user.id !== person.id">
						<div *ngIf="datos.isRevised" fxLayoutAlign="center center" class="btn-success btn-footer end" matTooltip="Revisado">Revisado</div>

						<div *ngIf="!datos.isRevised" fxLayoutAlign="center center" class="btn-danger btn-footer end" matTooltip="Pendiente" (click)="revisePA()">
							Pendiente revisar
						</div>
					</div>
				}
			</div>
			<!-- Edit mode -->
			<div *ngIf="editMode" class="botonera-right" fxLayoutAlign="center center">
				<div fxLayoutAlign="center center" class="btn-footer end" matTooltip="Cancelar" (click)="toogleEdit()">
					<mat-icon>close</mat-icon>
					Cancelar
				</div>
				@if (projectName == "iberext") {
					@if (isFormInvalid()) {
						<div fxLayoutAlign="center center" class="btn-success btn-footer end" matTooltip="Aplicar cambios" style="opacity: 0.3">
							<mat-icon>check</mat-icon>
							Aplicar cambios
						</div>
					} @else {
						<div fxLayoutAlign="center center" class="btn-success btn-footer end" matTooltip="Aplicar cambios" (click)="editPA()">
							<mat-icon>check</mat-icon>
							Aplicar cambios
						</div>
					}
				} @else {
					<div fxLayoutAlign="center center" class="btn-success btn-footer end" matTooltip="Aplicar cambios" (click)="editPA()">
						<mat-icon>check</mat-icon>
						Aplicar cambios
					</div>
				}
			</div>
		</div>
		@if (permisos.w) {
			<div *ngIf="!editable">
				<div *ngIf="!editMode" class="botonera-right" fxLayoutAlign="center center">
					<!-- Revision de PA -->
					<div
						*ngIf="revisionPeriodEnabled && user.id !== person.id && canBeBlocked"
						fxLayoutAlign="center center"
						class="btn-footer end"
						matTooltip="Desbloquear plan de acción"
						(click)="lockActionPlan(0)"
					>
						<mat-icon>lock_open</mat-icon>
					</div>
					<div *ngIf="revisionPeriodEnabled && user.id !== person.id">
						<div *ngIf="datos.isRevised" fxLayoutAlign="center center" class="btn-success btn-footer end" matTooltip="Revisado">Revisado</div>
						<div *ngIf="!datos.isRevised" fxLayoutAlign="center center" class="btn-danger btn-footer end" matTooltip="Pendiente" (click)="revisePA()">
							Pendiente revisar
						</div>
					</div>
				</div>
				<!-- Edit mode -->
			</div>
		}
	</div>

	<div *ngIf="showHistory && listHistory" class="p-16">
		<div *ngIf="listHistory.length" fxLayout="column">
			<mat-vertical-stepper orientation="vertical">
				<mat-step *ngFor="let history of listHistory">
					<ng-template matStepLabel>
						<p class="m-0">{{ history.description }}</p>
						<p class="m-0">{{ momentDate(history.creationDate) }}</p>
					</ng-template>
				</mat-step>
			</mat-vertical-stepper>
		</div>
		<div *ngIf="!listHistory.length" fxLayout="column">
			<p>El elemento seleccionado no contiene ninguna revisión.</p>
		</div>
	</div>

	<div *ngIf="showFormComent" class="p-16">
		@if (permisos.w) {
			<div fxLayout="row" fxLayoutAlign="center center">
				<div class="person-avatar mini-avatar pr-16" fxLayout="row" fxLayoutAlign="center flex-start">
					<img class="img-circle" [src]="user.img" />
				</div>
				<div class="p-8 mb-12 wrap-comment" fxLayout="column" fxFlex="1 1 0">
					<form class="" fxLayout="column" name="formComments" fxFlex="1 1 0" [formGroup]="formComments">
						<mat-form-field subscriptSizing="dynamic" appearance="outline" class="field-blank">
							<mat-label>Deja tu comentario</mat-label>
							<textarea matInput formControlName="comentario"></textarea>
						</mat-form-field>
						<div fxLayout="row" fxLayoutAlign="flex-end flex-end">
							<button mat-raised-button class="btn-feedbalia btn-comentar" (click)="addComment()" [disabled]="readOnly && !canComment">
								Comentar
							</button>
						</div>
					</form>
				</div>
			</div>
		}
		<div *ngFor="let itemComment of listComments" fxLayout="row" fxLayoutAlign="center center">
			<div class="person-avatar mini-avatar pr-16" fxLayout="row" fxLayoutAlign="center flex-start">
				<img class="img-circle" [src]="getImagePerson(itemComment.nickname)" [title]="itemComment.displayName" />
			</div>
			<!-- Read only -->
			<div *ngIf="!itemComment.editable" fxLayout="row" class="w-100-p">
				<div fxLayout="column" class="w-100-p">
					<small class="mt-4">{{ momentDate(itemComment.creationDate) }}</small>
					<div class="content-comment p-12">
						<p class="m-0">{{ itemComment.content }}</p>
					</div>
				</div>
				@if (permisos.w) {
					<div *ngIf="itemComment.personId === this.user.id" class="btn-comment-readonly" fxLayout="row">
						<div fxLayoutAlign="center center" class="btn-comment end" matTooltip="Editar comentario" (click)="toogleEditComment(itemComment)">
							<mat-icon>edit</mat-icon>
						</div>
						@if (permisos.d) {
							<div fxLayoutAlign="center center" class="btn-comment end" matTooltip="Eliminar comentario" (click)="removeComment(itemComment)">
								<mat-icon>delete</mat-icon>
							</div>
						}
					</div>
				}
			</div>
			<!-- Editable -->
			<div *ngIf="itemComment.editable" fxLayout="row" class="w-100-p" fxLayoutAlign="center center">
				<div fxLayout="column" class="w-100-p">
					<div class="content-comment p-12">
						<input matInput #newContent class="m-0 font-size-18 w-100-p" [value]="itemComment.content" />
					</div>
				</div>
				@if (permisos.w) {
					<div *ngIf="itemComment.personId === this.user.id" class="btn-comment-editable" fxLayout="row" fxLayoutAlign="flex-end flex-end">
						<div fxLayoutAlign="center center" class="btn-comment end" matTooltip="Enviar" (click)="editComment(itemComment, newContent.value)">
							<mat-icon>check</mat-icon>
						</div>

						<div fxLayoutAlign="center center" class="btn-comment end" matTooltip="Cancelar" (click)="toogleEditComment(itemComment)">
							<mat-icon>close</mat-icon>
						</div>
					</div>
				}
			</div>
		</div>
	</div>
</div>
