<div
	fxLayout="row"
	fxLayoutAlign="center center"
	class="w-100-p"
	style="width: 100% !important; max-width: none !important; height: 100%; position: relative"
>
	<div fxLayout="column" fxLayoutAlign="center center" fxLayout="1 1 0" style="width: 10%">
		<button mat-mini-fab (click)="changeIt(-1)" [disabled]="index == 0"><mat-icon>arrow_back_ios</mat-icon></button>
	</div>
	<div fxLayout="column" fxLayout="8 1 0" fxLayoutAlign="center center" style="width: 100%">
		<div fxLayout="row" fxLayoutAlign="center center" class="w-100-p label">
			<div fxLayout="column" fxLayoutAlign="center center" class="w-100-p">
				<span
					style="
						color: var(--feedbalia-color-background-500);
						background-color: var(--feedbalia-color-primary-500);
						border-radius: 5px;
						padding: 5px 10px;
					"
					>{{ index + 1 }}/{{ max + 1 }}</span
				>
			</div>
		</div>
		<div fxLayout="row" fxLayoutAlign="center center" class="w-100-p">
			<div fxLayout="column" fxLayoutAlign="center center" class="w-100-p">
				@if (selected?.type == "img") {
					<img class="imagenpw" [src]="selected.thumbImage" alt="-" />
				}
				@if (selected?.type == "file") {
					<div
						fxLayout="row"
						fxLayoutAlign="center center"
						style="
							width: 200px;
							height: 200px;
							border: 3px solid var(--feedbalia-color-primary-500);
							background-color: var(--feedbalia-color-background-500);
							border-radius: 5px;
							margin: 2px;
							cursor: pointer;
						"
					>
						<div fxLayout="column" fxLayoutAlign="center center" style="cursor: pointer">
							<div fxLayout="row" fxLayoutAlign="center center" style="cursor: pointer">
								<fa-icon style="cursor: pointer; font-size: 30px" icon="file"></fa-icon>
							</div>
							<div fxLayout="row" fxLayoutAlign="center center" style="cursor: pointer">
								<span class="mt-8" style="text-align: center; font-size: 17px; font-style: italic">
									{{ selected.title }}
								</span>
							</div>
						</div>
					</div>
					<div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px">
						<button mat-raised-button (click)="previewDocument(selected)">Visualizar documento</button>
					</div>
				}
			</div>
		</div>
	</div>
	<div fxLayout="column" fxLayoutAlign="center center" fxLayout="1 1 0" style="width: 10%">
		<button mat-mini-fab (click)="changeIt(1)" [disabled]="index == max"><mat-icon>arrow_forward_ios</mat-icon></button>
	</div>
</div>
